import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {ISchedule} from 'app/blocks/model/schedule.model';
import {IRoute} from 'app/blocks/model/route.model';
import {IProduct} from 'app/blocks/model/product.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IProductAttribute} from 'app/blocks/model/product-attirbute.model';

export interface IStandingOrderItem extends IBaseEntity {
    id?: number;
    product: IProduct;
    quantity?: number;
    caseQuantity?: number;
    eachQuantiy?: number;
    orderItemAttributes?: IProductAttribute[];
}

export interface IStandingOrder extends IBaseEntity {
    id?: number;
    schedule: ISchedule;
    orderItems?: IStandingOrderItem[];
    route?: IRoute;
    deliveryPoint?: IDeliveryPoint;
    owningFranchiseId?: number;
    assignedFranchiseId?: number;
    type: any;
    description?: string;
}

export class StandingOrderItem extends BaseEntity implements IStandingOrderItem {
    public id?: number;
    public product: IProduct;
    public quantity?: number;
    public caseQuantity?: number;
    public eachQuantiy?: number;
    public orderItemAttributes?: IProductAttribute[];

    constructor(standingOrderItem?) {
        super();
        if (standingOrderItem) {
            this.id = standingOrderItem.id;
            this.product = standingOrderItem.product;
            this.quantity = standingOrderItem.quantity;
            this.caseQuantity = standingOrderItem.caseQuantity;
            this.eachQuantiy = standingOrderItem.eachQuantity;
            this.orderItemAttributes = standingOrderItem.orderItemAttributes || [];
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): StandingOrderItem {
        return new StandingOrderItem(this);
    }
}

export class StandingOrder extends BaseEntity implements IStandingOrder {
    public id?: number;
    public schedule: ISchedule;
    public orderItems?: IStandingOrderItem[];
    public route?: IRoute;
    public assignedFranchiseId?: number;
    public owningFranchiseId?: number;
    public deliveryPoint?: IDeliveryPoint;
    public type: any;
    public description?: string;

    constructor(standingOrder?) {
        super();
        if (standingOrder) {
            this.id = standingOrder.id;
            this.schedule = standingOrder.schedule;
            this.orderItems = standingOrder.orderItems || [];
            this.route = standingOrder.route;
            this.assignedFranchiseId = standingOrder.assignedFranchiseId;
            this.owningFranchiseId = standingOrder.owningFranchiseId;
            this.deliveryPoint = standingOrder.deliveryPoint;
            this.type = standingOrder.type;
            this.description = standingOrder.description;
        } else {
            this.orderItems = [];
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): StandingOrder {
        return new StandingOrder(this);
    }
}
